<template>
  <div class="container">
    <header class="header">
      <h1>房间信息</h1>
      <button @click="logout" class="logout-button">退出登录</button> <!-- 添加退出登录按钮 -->
    </header>
    <div class="activation-section">
      <h2>激活账户</h2>
      <input v-model="activationCode" placeholder="输入激活码" class="input-field" />
      <button @click="activateAccount">激活</button>
    </div>
    <div v-if="isActivated">
        <div>
      <p>到期时间: {{ expiryTime }}</p> <!-- 添加显示到期时间 -->
    </div>
      <div class="button-group">
        <button @click="view = 'normal'" :class="{ active: view === 'normal' }">普通房间</button>
        <button @click="view = 'club'" :class="{ active: view === 'club' }">俱乐部房间</button>
      </div>

      <div v-if="view === 'normal'" class="content">
        <h2>普通房间信息</h2>
        <input v-model="searchNumber" placeholder="输入房间号" :disabled="isBound" class="input-field" />
        <div class="button-group">
          <button @click="bindRoomByNumber" :disabled="isBound">绑定</button>
          <button @click="unbindRoom">解绑</button>
          <button @click="manualRefresh" :disabled="!isBound">现在刷新</button>
        </div>
        <div v-if="rooms.length > 0" class="rooms-container">
          <h3>每隔5s自动刷新房间数据</h3>
          <div class="room-row" v-for="roomGroup in groupedRooms" :key="roomGroup[0].roomNumber">
            <div class="room-card" v-for="room in roomGroup" :key="room.userId">
              <p>房间号: {{ room.roomNumber }}</p>
              <p>牌型:</p>
              <ul>
                <li v-for="(card, index) in parseCardInfo(room.cardInfo)" :key="index">
                  <img :src="card.image" :alt="card.name" class="card-image">
                </li>
              </ul>
              <p>用户名称: {{ room.nickname }}</p>
              <p>房间名: {{ room.roomName }}</p>
              <p>更新时间: {{ room.updatedAt }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="view === 'club'" class="content">
        <h2>俱乐部房间信息</h2>
        <input v-model="searchClubId" placeholder="输入俱乐部号" class="input-field" />
        <input v-model="searchRoomName" placeholder="输入房间名" class="input-field" />
        <div class="button-group">
          <button @click="bindRoomByClubAndName" :disabled="isBound">绑定</button>
          <button @click="unbindRoom">解绑</button>
          <button @click="manualRefresh" :disabled="!isBound">现在刷新</button>
        </div>
        <div v-if="rooms.length > 0" class="rooms-container">
          <h3>每隔5s自动刷新房间数据</h3>
          <div class="room-row" v-for="roomGroup in groupedRooms" :key="roomGroup[0].roomNumber">
            <div class="room-card" v-for="room in roomGroup" :key="room.userId">
              <p>俱乐部ID: {{ room.clubId }}</p>
              <p>房间号: {{ room.roomNumber }}</p>
              <p>牌型:</p>
              <ul>
                <li v-for="(card, index) in parseCardInfo(room.cardInfo)" :key="index">
                  <img :src="card.image" :alt="card.name" class="card-image">
                </li>
              </ul>
              <p>用户名称: {{ room.nickname }}</p>
              <p>房间名: {{ room.roomName }}</p>
              <p>更新时间: {{ room.updatedAt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div v-else>
      <p>账户未激活，请先激活账户。</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// 设置默认的 Token 头
axios.defaults.headers.common['X-Auth-Token'] = 'LongZhanYuYe'; // 替换为你的 token

// 映射表，将数值映射到具体的牌和图片路径
const cardMapping = [
  { name: "♦A", image: require('@/assets/cards/♦A.png') },
  { name: "♦2", image: require('@/assets/cards/♦2.png') },
  { name: "♦3", image: require('@/assets/cards/♦3.png') },
  { name: "♦4", image: require('@/assets/cards/♦4.png') },
  { name: "♦5", image: require('@/assets/cards/♦5.png') },
  { name: "♦6", image: require('@/assets/cards/♦6.png') },
  { name: "♦7", image: require('@/assets/cards/♦7.png') },
  { name: "♦8", image: require('@/assets/cards/♦8.png') },
  { name: "♦9", image: require('@/assets/cards/♦9.png') },
  { name: "♦10", image: require('@/assets/cards/♦10.png') },
  { name: "♦J", image: require('@/assets/cards/♦J.png') },
  { name: "♦Q", image: require('@/assets/cards/♦Q.png') },
  { name: "♦K", image: require('@/assets/cards/♦K.png') },
  { name: "♣A", image: require('@/assets/cards/♣A.png') },
  { name: "♣2", image: require('@/assets/cards/♣2.png') },
  { name: "♣3", image: require('@/assets/cards/♣3.png') },
  { name: "♣4", image: require('@/assets/cards/♣4.png') },
  { name: "♣5", image: require('@/assets/cards/♣5.png') },
  { name: "♣6", image: require('@/assets/cards/♣6.png') },
  { name: "♣7", image: require('@/assets/cards/♣7.png') },
  { name: "♣8", image: require('@/assets/cards/♣8.png') },
  { name: "♣9", image: require('@/assets/cards/♣9.png') },
  { name: "♣10", image: require('@/assets/cards/♣10.png') },
  { name: "♣J", image: require('@/assets/cards/♣J.png') },
  { name: "♣Q", image: require('@/assets/cards/♣Q.png') },
  { name: "♣K", image: require('@/assets/cards/♣K.png') },
  { name: "♥A", image: require('@/assets/cards/♥A.png') },
  { name: "♥2", image: require('@/assets/cards/♥2.png') },
  { name: "♥3", image: require('@/assets/cards/♥3.png') },
  { name: "♥4", image: require('@/assets/cards/♥4.png') },
  { name: "♥5", image: require('@/assets/cards/♥5.png') },
  { name: "♥6", image: require('@/assets/cards/♥6.png') },
  { name: "♥7", image: require('@/assets/cards/♥7.png') },
  { name: "♥8", image: require('@/assets/cards/♥8.png') },
  { name: "♥9", image: require('@/assets/cards/♥9.png') },
  { name: "♥10", image: require('@/assets/cards/♥10.png') },
  { name: "♥J", image: require('@/assets/cards/♥J.png') },
  { name: "♥Q", image: require('@/assets/cards/♥Q.png') },
  { name: "♥K", image: require('@/assets/cards/♥K.png') },
  { name: "♠A", image: require('@/assets/cards/♠A.png') },
  { name: "♠2", image: require('@/assets/cards/♠2.png') },
  { name: "♠3", image: require('@/assets/cards/♠3.png') },
  { name: "♠4", image: require('@/assets/cards/♠4.png') },
  { name: "♠5", image: require('@/assets/cards/♠5.png') },
  { name: "♠6", image: require('@/assets/cards/♠6.png') },
  { name: "♠7", image: require('@/assets/cards/♠7.png') },
  { name: "♠8", image: require('@/assets/cards/♠8.png') },
  { name: "♠9", image: require('@/assets/cards/♠9.png') },
  { name: "♠10", image: require('@/assets/cards/♠10.png') },
  { name: "♠J", image: require('@/assets/cards/♠J.png') },
  { name: "♠Q", image: require('@/assets/cards/♠Q.png') },
  { name: "♠K", image: require('@/assets/cards/♠K.png') }
];

export default {
  data() {
    return {
      view: 'normal', // 当前视图状态
      searchNumber: '',
      searchClubId: '',
      searchRoomName: '',
      rooms: [], // 用于存储返回的 List<Room>
      intervalId: null, // 用于存储定时器的ID
      isBound: false, // 用于指示是否已绑定
      activationCode: '', // 用户输入的激活码
      uname: '' ,// 添加用户名变量
      isActivated: false,// 添加激活状态变量
      expiryTime: null // 添加到期时间变量/////////////////////
    };
  },
  watch: {
    view(newView, oldView) {
      if (newView !== oldView) {
        this.unbindRoom();
      }
    }
  },
  // computed: {
  //   groupedRooms() {
  //     const groups = {};
  //     this.rooms.forEach(room => {
  //       if (!groups[room.roomNumber]) {
  //         groups[room.roomNumber] = [];
  //       }
  //       groups[room.roomNumber].push(room);
  //     });
  //     return Object.values(groups);
  //   }
  // },
  computed: {
  groupedRooms() {
    const groups = [];
    this.rooms.forEach((room, index) => {
      // 确定房间应该属于哪个组
      const groupIndex = Math.floor(index / 4);
      if (!groups[groupIndex]) {
        groups[groupIndex] = [];
      }
      groups[groupIndex].push(room);
    });
    return groups;
  }
},
  methods: {
    parseCardInfo(cardInfo) {
      if (cardInfo) {
        try {
          const cardNumbers = JSON.parse(cardInfo);
          return cardNumbers.map(number => cardMapping[number]);
        } catch (e) {
          return [];
        }
      }
      return [];
    },
    async fetchRoomByNumber() {
      try {
        console.log("Fetching room by number with JWT:", sessionStorage.getItem('jwtToken')); ////////////// 添加这个
        const response = await axios.get(`https://dpxielunyan.top/api/info/number/${this.searchNumber}`);
        console.log('Search response data:', response.data);
        // this.rooms = response.data; // 将返回的 List<Room> 设置到 rooms 中 这个替换下面3行 就是不处理的显示更新时间
      this.rooms = response.data.map(room => {
      room.updatedAt = this.formatDateTimeToSecond(room.updatedAt);
      return room;
    });
      } catch (error) {
        console.error('Error fetching room by number:', error);
        this.rooms = [];
      }
    },
    async fetchRoomByClubAndName() {
      try {
        const response = await axios.get(`https://dpxielunyan.top/api/info/clubIdAndRoomName`, {
          params: {
            clubId: this.searchClubId,
            roomName: this.searchRoomName
          }
        });
        console.log('Search response data:', response.data);
        // this.rooms = response.data; // 将返回的 List<Room> 设置到 rooms 中  这个替换下面3行 就是不处理的显示更新时间
        this.rooms = response.data.map(room => {
      room.updatedAt = this.formatDateTimeToSecond(room.updatedAt);
      return room;
    });
      } catch (error) {
        console.error('Error fetching room by club and name:', error);
        this.rooms = [];
      }
    },
    async activateAccount() {
      try {
        const response = await axios.post('https://dpxielunyan.top/api/activation/activate', null, {
          params: {
            uname: this.uname, // 使用存储在组件中的用户名
            activationCode: this.activationCode
          },
          headers: {
            'Accept': 'application/json'  // 确保请求头包含 Accept
          }
        });
        if (response.data.success) { // 根据后端返回的数据结构判断激活成功
          alert('账户激活成功');
          this.checkActivationStatus(); // 激活成功后立即更新激活状态
        } else {
          alert('激活码无效或已使用');
        }
      } catch (error) {
        console.error('激活账户时出错:', error);
        alert('激活账户时发生错误');
      }
    },
    async checkActivationStatus() {
    try {
      const response = await axios.get('https://dpxielunyan.top/user/isactivate', {
        params: {
          uname: this.uname,
        }
      });
      console.log(response.data); // 添加日志输出，检查返回的数据
      if (response.data.success) {
        this.isActivated = true;
        this.expiryTime = response.data.expiryTime; // 获取并存储到期时间////////////
      } else {
        this.isActivated = false;
        this.expiryTime = null;/////////////
      }
    } catch (error) {
      console.error('Error checking activation status:', error);
      this.isActivated = false;
      this.expiryTime = null;
    }
  },
///检查是否  单一登录
  async checkSessionStatus() {
      try {
        console.log("Checking session status..."); ////////////////////////////////// 添加这个
        const response = await axios.get('https://dpxielunyan.top/user/protectedEndpoint', {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}` // 从本地存储获取JWT
          },
        });
        console.log("Session check response:", response); ////////////////////////////////// 添加这个
        // 如果返回的状态码为401，表示会话无效，触发自动退出逻辑
        if (response.status === 401) {
          this.logout(); // 调用退出方法
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.logout(); // 捕获401错误并调用退出方法
        } else {
          console.error('Error checking session status:', error);
        }
      }
    },


    bindRoomByNumber() {
      if (this.isBound) {
        alert('Please unbind the current room first.');
        return;
      }
      this.isBound = true;
      this.fetchRoomByNumber(); // 立即获取一次数据
      this.intervalId = setInterval(() => {
        this.fetchRoomByNumber();
      }, 5000); // 每5秒刷新一次
    },
    bindRoomByClubAndName() {
      if (!this.searchClubId || !this.searchRoomName) {
        alert('请输入俱乐部号和房间名。');
        return;
      }
      if (this.isBound) {
        alert('Please unbind the current room first.');
        return;
      }
      this.isBound = true;
      this.fetchRoomByClubAndName(); // 立即获取一次数据
      this.intervalId = setInterval(() => {
        this.fetchRoomByClubAndName();
      }, 5000); // 每5秒刷新一次
    },
    unbindRoom() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.rooms = [];
        this.isBound = false;
      }
    },
    manualRefresh() {
      if (this.isBound) {
        if (this.view === 'normal') {
          this.fetchRoomByNumber();
        } else if (this.view === 'club') {
          this.fetchRoomByClubAndName();
        }
      }
    },
   formatDateTimeToSecond(datetime) {
    if (!datetime) return '';
    const date = new Date(datetime);
    return date.toLocaleString('zh-CN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  },
   logout() {
      console.log("Logging out... Clearing sessionStorage..."); /////////////// 添加这个
      sessionStorage.clear();
      localStorage.clear();
      console.log("sessionStorage after clearing:", sessionStorage.getItem('jwtToken')); ///////// 添加这个
      axios.post('https://dpxielunyan.top/user/logout')
      ////这个具体 api还要再看
        .then(() => {
          console.log("Logout request sent"); // 添加日志输出
          this.$router.push('/login');
        })
        .catch(error => {
          console.error('Logout error:', error);
        });
    }
  },
  mounted() {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log("User info loaded from sessionStorage:", userInfo); ///////////////////////// 添加这个
    if (userInfo && userInfo.uname) {
      this.uname = userInfo.uname;
      this.checkActivationStatus(); // 立即检查激活状态的方法
     // this.checkSessionStatus();    // 立即检查会话状态
       // 定期检查激活状态，每分钟一次
      this.statusCheckInterval = setInterval(this.checkActivationStatus, 60000);
      // 定期检查单一设备登录状态，每分钟一次
    this.sessionCheckInterval = setInterval(this.checkSessionStatus, 60000);
    } else {
      alert('用户信息未找到，请重新登录');
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    // 清除激活状态检查的定时器
    if (this.statusCheckInterval) {
      clearInterval(this.statusCheckInterval);
    }
     // 清除单一设备登录状态检查的定时器
    if (this.sessionCheckInterval) {
    clearInterval(this.sessionCheckInterval);
  }
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.button-group {
  margin: 10px 0;
}

.button-group button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.button-group button.active {
  background-color: #007bff;
  color: white;
}

.input-field {
  margin: 10px 5px;
  padding: 10px;
  font-size: 16px;
  width: 200px;
}

.rooms-container {
  margin-top: 20px;
}

.room-row {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.room-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 20px;
  width: 200px;
  background-color: #f9f9f9;
}

.card-image {
  width: 50px;
  height: 70px;
}
.logout-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
</style>
