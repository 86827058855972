<template>
  <div>
    <el-card class="box-card">
      <h2>写轮眼</h2>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-position="left"
        label-width="70px"
        class="login-from"
      >
        <el-form-item label="用户名" prop="uname">
          <el-input v-model="ruleForm.uname"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha" class="captcha-item">
          <el-row :gutter="10">
            <el-col :span="14">
              <el-input v-model="ruleForm.captcha" placeholder="请输入验证码"></el-input>
            </el-col>
            <el-col :span="10">
              <img :src="captchaSrc" @click="refreshCaptcha" alt="验证码" class="captcha-img" />
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div class="btnGroup">
        <el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">
          登录
        </el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      captchaSrc: '',  // 初始为空，稍后通过请求获取
      ruleForm: {
        uname: "",
        password: "",
        captcha: "",  // 用户输入的验证码
        captchaCode: ""  // 从后端获取的验证码（不直接显示）
      },
      rules: {
        uname: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "验证码不能为空！", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          axios({
            url: "https://dpxielunyan.top/user/login",
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: {
              uname: this.ruleForm.uname,
              password: this.ruleForm.password,
              captcha: this.ruleForm.captcha,  // 用户输入的验证码
              captchaCode: this.ruleForm.captchaCode  // 后端返回的验证码值
            },
          })
            .then((res) => {
              if (res.data.code === "0") {
                console.log("ssss"); 
                console.log(res.data); // 打印整个响应数据
                console.log(res.data.data.jwtToken); // 打印token
                 // 假设后端返回的JWT令牌在res.data.token中
              // 存储JWT令牌
           sessionStorage.setItem("jwtToken",res.data.data.jwtToken);
          sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));///貌似可以省略的

                this.$router.push('/home');
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
              this.loading = false;
              this.refreshCaptcha(); // 刷新验证码
            })
            .catch((error) => {
              let errorMessage = '网络错误，请稍后再试';
              if (error.response) {
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
                errorMessage = error.response.data.msg || errorMessage;
              } else if (error.request) {
                console.error('Error request:', error.request);
              } else {
                console.error('Error message:', error.message);
              }
              this.$message({
                message: errorMessage,
                type: "error",
              });
              this.loading = false;
              this.refreshCaptcha(); // 刷新验证码
            });
        } else {
          this.$message({
            message: '请填写必填项',
            type: "warning",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.refreshCaptcha(); // 重置表单时刷新验证码
    },
    refreshCaptcha() {
      axios.get('https://dpxielunyan.top/user/captcha')
        .then((response) => {
          this.captchaSrc = 'data:image/jpeg;base64,' + response.data.image;  // 显示验证码图片
          this.ruleForm.captchaCode = response.data.captcha;  // 保存验证码值用于验证
        })
        .catch((error) => {
          console.error('Error fetching captcha:', error);
        });
    }
  },
  mounted() {
    this.refreshCaptcha(); // 初始化时刷新验证码
  }
};
</script>

<style scoped>
.box-card {
  margin: auto auto;
  width: 400px;
}
.login-from {
  margin: auto auto;
}
.captcha-item .el-row {
  display: flex;
  align-items: center;
}
.captcha-img {
  cursor: pointer;
  height: 40px;
}
</style>